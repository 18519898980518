@import url(https://fonts.googleapis.com/css?family=Montserrat:500,600,700,800&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'Montserrat', sans-serif;
  color: #282828;
  padding: 0;
}

a {
  text-decoration: none;
  color: #282828;
}

a :active {
  color: #282828;
}


button {
  padding: 0;
	cursor: pointer;
	text-decoration: none;
	border: none;
	outline: none;
  -webkit-tap-highlight-color: transparent;
}

button :active {
  color: #282828;
}

p {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

button {
  font-family: 'Montserrat', sans-serif;
}


.Main {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
}

.Main .imageCollection {
	order: 3;
	width: 459px;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	margin: 60px 0 0 0;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.Main .mainImageWrapper {
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	position: relative;
	margin-bottom: -4px;
}

.Main .imageCollection .mainImageWrapper {
	margin-bottom: 40px;
}

.Main .imageCollection img {
	width: 459px;
	height: 575px;
	object-fit: cover;
	background-color: black;
}

.Main .sorceTag {
	position: absolute;
	bottom: 10px; right: 10px;
	color: white;
	font-size: 6px;
	font-weight: 700;
}

.Main .sorceTag span {
	font-size: 9px;
}


/* Item Collectio */
.Main .outerItemCollection {
	order: 3;
	margin: 60px 60px 0 0;
}

.Main .itemCollection {
	margin-bottom: 40px;
	display: flex;
	/* width: 398px; */
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	height: 575px;
	justify-content: space-between;
	overflow: auto;
}

.Main .itemCollection .collection-2 {
	margin-left: 40px;
}

.Main .itemCollection .collection-3 {
	margin-left: 40px;
}

.Main .item {
	width: 169px;
	margin-bottom: 40px;
}	

.Main .imageWrapper {
	position: relative;
}

.Main img {
	width: 100%;
	object-fit: contain;
}

.Main .item a {
	left: 0; top: 0;
	position: absolute;
	width: 100%;
	height: calc(100% - 4px);
	-webkit-transition: 0.21s ease-in;
	transition: 0.21s ease-in;
	-webkit-transition: .21s ease-in;
	color: rgb(255, 255, 255, 0);
	display: flex;
	justify-content: center;
	align-items: center;
}

.Main .imageWrapper:hover a {
	-webkit-backdrop-filter: blur(5px);
	        backdrop-filter: blur(5px);
	color: rgb(255, 255, 255, 1);
	background-color: rgba(0, 0, 0, 0.25);
}

.Main .imageWrapper:hover p {
	opacity: 1;
}

.Main .item button {
	margin-top: -4px;
	width: 100%;
	height: 36px;
	background-color: white;
	font-weight: 700;
	font-size: 10px;
	color: #282828;
	border: 1px solid #282828;
	-webkit-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
	-webkit-transition: .3s ease-in-out;
}

.Main .item button:hover {
	background-color: #282828;
	color: white;
}









/* phone */
@media only screen and (max-width: 1110px) {
	.Main {
		flex-direction: column;
		/* align-items: center; */
		align-items: center;
		width: 100vw;
	}


	.Main .outerItemCollection {
		margin: 50px 0 170px 0;
		padding: 0;
		display: flex;
		flex-direction: column;
	}

	.Main .itemCollection {
		display:flex;
		flex-direction: column;
		margin: 0 0 50px 0;
		width: 459px;
		/* height: 161px; */
		height: -webkit-max-content;
		height: -moz-max-content;
		height: max-content;
		/* overflow: auto; */
	}

	.Main .itemCollection .collection-1 {
		display: flex;
		width: 459px;
		height: 161px;
		overflow: auto;
	}

	.Main .item {
		height: 125px;
		width: -webkit-max-content;
		width: -moz-max-content;
		width: max-content;
		margin: 0;
		position: relative;
	}	

	.Main .item .imageWrapper {
		height: 125px;
		width: -webkit-max-content;
		width: -moz-max-content;
		width: max-content;
	}	

	.Main .imageWrapper img {
		width: auto;
		height: 100%;
		object-fit: contain;
	}
	.Main .imageCollection img { margin: 0;}



	.Main .imageWrapper:hover a {
		-webkit-backdrop-filter: blur(0);
		        backdrop-filter: blur(0);
		color: #282828;
		background-color: rgba(0, 0, 0, 0);
	}

	.Main .imageWrapper:hover p {
		opacity: 1;
	}

	.Main .item button {
		margin-top: 0px;
	}

	.Main .item button:hover {
		background-color: white;
		color: #282828;
	}
}


@media only screen and (max-width: 459px) {
	.Main {
		max-width: 100vw;
	}

	.Main .outerItemCollection {
		margin: 30px 0 200px 0;
	}

	.Main .imageCollection {
		margin-top: 32px;
		width: 100vw;
	}
	
	.Main .imageCollection div {
		width: 100vw;
		height: -webkit-max-content;
		height: -moz-max-content;
		height: max-content;
		/* height: 200px; */
	}

	.Main .itemCollection .collection-1 {
		width: 100vw;
	}
	
	.Main .imageCollection img {
		width: 100vw;
		height: auto;
		object-fit: cover;
	}

	.Main .itemCollection {
		margin: 0px 0 0px 0;
		width: 100vw;
	}
}

.SideMenu {
	width: 250px;
	min-height: 100vh;
	height: 100%;
	padding: 60px 0 36px 60px;
	box-sizing: border-box;
	order: 1;
	z-index: 100;
}

.SideMenu .fixed-wrapper {
	position: fixed;
}

.SideMenu h1 {
	margin: 0;
}

.SideMenu .header1 {
	margin: 0;
	font-size: 22px;
	color: #282828;
	font-weight: 800;
	cursor: pointer;
}

.SideMenu h2 {
	margin: 0; margin-top: 6px;
	font-size: 15px;
	letter-spacing: 2px;
	font-weight: 600;
	color: #282828;
}

.SideMenu p {
	font-size: 15px;
	font-weight: 600;
}

.SideMenu .filterSection {
	display: flex;
	margin-top: 66px;
}

.SideMenu .filterSection .iconWrapper {
	background-color: white;
	padding-left: 7px;
	padding-right: 13px;
	height: 18px;
	z-index: 11;
	display: flex;
	align-items: center;
}

.SideMenu .filterSection img {
	z-index: 11;
	transition: 0.4s;
	-webkit-transition: 0.4s;
	transform-origin: left left;
	-webkit-transform-origin: left left;
}

.SideMenu .genderContainer {
	display: flex;
	align-items: center;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
}

.SideMenu .genderContainer p {
	top: 0;
	background-color: white;
}

.SideMenu .genderContainer .genderLink {
	top: 0; left: 0;
	background-color: white;
	padding: 0;
	font-size: 15px;
	font-weight: 600;
	font-family: 'Montserrat', sans-serif;
	-webkit-transition: 0.5s;
	transition: 0.5s;
	-webkit-transition-timing-function: cubic-bezier(0.52, 0.16, 0.24, 1);
	transition-timing-function: cubic-bezier(0.52, 0.16, 0.24, 1);
	text-decoration: none;

}





.SideMenu .seasonContainer {
	display: flex;
	align-items: center;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	/* margin-left: 18px; */
}


/* ****** FOOTER ****** */
.SideMenu footer {
	position: absolute;
	bottom: 60px;
}

.SideMenu .contact {
	font-size: 12px;
	letter-spacing: 2px;
	text-transform: uppercase;
	text-decoration: none;
	color: #282828;
}

.SideMenu .line {
	margin: 18px 0;
	width: 36px;
	height: 0.0625rem;
	background-color: #282828;
}

.SideMenu a {
	font-size: 8px;
	letter-spacing: 2px;
	line-height: 10px;
}

.SideMenu a span {
	font-size: 12px;
	letter-spacing: 0px;
	font-weight: 700;
}




/* phone */
@media only screen and (max-width: 1110px) {
	.SideMenu {
		align-self: center;
		width: 459px;	
		min-height: 0;
		height: 100%;
		padding: 40px 0 0 0px;
		box-sizing: border-box;
		order: 1;
	}

	.SideMenu .fixed-wrapper {
		position: static;
	}
}

/* phone */
@media only screen and (max-width: 459px) {
	.SideMenu {
		align-self: flex-start;
		width: 100vw;	
		min-height: 0;
		height: 100%;
		padding: 22px 0 0 32px;
		box-sizing: border-box;
		order: 1;
	}

	
	.SideMenu h2 {
		margin-top: 0;
		font-size: 9px;
		letter-spacing: 1.2px;
	}

	.SideMenu p {
		font-size: 14px;
	}
	
	.SideMenu .filterSection {
		margin-top: 24px;
	}
}

.SideCollection {
	position: absolute;

}


.SideWall {
	z-index: 100;
	position: fixed;
	width: 330px;
	top: 0;
	height: 100%;
	background-color: rgba(5, 5, 5, 1);
	-webkit-transition: 0.35s ease-in-out;
	transition: 0.35s ease-in-out;
	text-align: right;
	/* backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px); */
}

/* .Preview-sideMenu div { background-color: rgba(0,0,0,0.0);} */




 .SideWall button {
	margin: 25px 25px 0 0;
	padding: 10px;
	box-sizing: content-box;
	position: relative;
	width: 27px;
	height: 21px;
	background-color: rgba(0,0,0,0.0);
}

  .SideWall button div {
	background-color: white;
	display: block;
	position: absolute;
	height: 2.3px;
	width: 27px;
	/* -webkit-transition: .3s ease-in-out;
	-moz-transition: .3s ease-in-out;
	-o-transition: .3s ease-in-out;
	transition: .3s ease-in-out; */
}

 .topLayer {
	top: 4px;
	/* -webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg); */
}

 .bottomLayer {
	top: 14.7px;
	/* -webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg); */
}

 .topLayer-open {
	top: 9px;
	-webkit-transform: rotate(135deg);
	transform: rotate(135deg);
}

 .bottomLayer-open {
	top: 9px;
	-webkit-transform: rotate(-135deg);
	transform: rotate(-135deg);
}




/* Collection */
.SideCollection .collection {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 0 60px 0;
	height: calc(100vh - 41px);
	position: relative;
	overflow: auto;
}

.SideCollection .imageWrapper {
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	margin-top: 40px;
	position: relative;
}

.SideCollection .imageWrapper a {
	left: 0; top: 0;
	width: 100%;
	height: calc(100% - 4px);
	position: absolute;
	-webkit-transition: 0.21s ease-in;
	transition: 0.21s ease-in;
	-webkit-transition: .21s ease-in;
	color: rgb(255, 255, 255, 0);
	display: flex;
	justify-content: center;
	align-items: center;
}

.SideCollection .imageWrapper img {
	width: 211px;
	height: auto;
	object-fit: contain;
}





.contact {
	display: flex;
}

.contact .mail-wrapper {
	position: absolute;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.contact .mail-wrapper a {
	font-size: 12px;
	font-weight: 700;
	letter-spacing: 1px;
	text-transform: uppercase;
}
.Page404 {
	display: flex;
}

.Page404 .content-wrapper {
	position: absolute;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Page404 .content-wrapper p {
	font-size: 50px;
	font-weight: 700;
	letter-spacing: 10px;
	text-transform: uppercase;
}

.Page404 .content-wrapper p span {
	font-size: 18px;
	font-weight: 700;
	letter-spacing: 0px;
}
