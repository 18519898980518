.Main {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: max-content;
}

.Main .imageCollection {
	order: 3;
	width: 459px;
	height: max-content;
	margin: 60px 0 0 0;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.Main .mainImageWrapper {
	height: max-content;
	position: relative;
	margin-bottom: -4px;
}

.Main .imageCollection .mainImageWrapper {
	margin-bottom: 40px;
}

.Main .imageCollection img {
	width: 459px;
	height: 575px;
	object-fit: cover;
	background-color: black;
}

.Main .sorceTag {
	position: absolute;
	bottom: 10px; right: 10px;
	color: white;
	font-size: 6px;
	font-weight: 700;
}

.Main .sorceTag span {
	font-size: 9px;
}


/* Item Collectio */
.Main .outerItemCollection {
	order: 3;
	margin: 60px 60px 0 0;
}

.Main .itemCollection {
	margin-bottom: 40px;
	display: flex;
	/* width: 398px; */
	width: max-content;
	height: 575px;
	justify-content: space-between;
	overflow: auto;
}

.Main .itemCollection .collection-2 {
	margin-left: 40px;
}

.Main .itemCollection .collection-3 {
	margin-left: 40px;
}

.Main .item {
	width: 169px;
	margin-bottom: 40px;
}	

.Main .imageWrapper {
	position: relative;
}

.Main img {
	width: 100%;
	object-fit: contain;
}

.Main .item a {
	left: 0; top: 0;
	position: absolute;
	width: 100%;
	height: calc(100% - 4px);
	transition: 0.21s ease-in;
	-webkit-transition: .21s ease-in;
	color: rgb(255, 255, 255, 0);
	display: flex;
	justify-content: center;
	align-items: center;
}

.Main .imageWrapper:hover a {
	backdrop-filter: blur(5px);
	color: rgb(255, 255, 255, 1);
	background-color: rgba(0, 0, 0, 0.25);
}

.Main .imageWrapper:hover p {
	opacity: 1;
}

.Main .item button {
	margin-top: -4px;
	width: 100%;
	height: 36px;
	background-color: white;
	font-weight: 700;
	font-size: 10px;
	color: #282828;
	border: 1px solid #282828;
	transition: 0.3s ease-in-out;
	-webkit-transition: .3s ease-in-out;
}

.Main .item button:hover {
	background-color: #282828;
	color: white;
}









/* phone */
@media only screen and (max-width: 1110px) {
	.Main {
		flex-direction: column;
		/* align-items: center; */
		align-items: center;
		width: 100vw;
	}


	.Main .outerItemCollection {
		margin: 50px 0 170px 0;
		padding: 0;
		display: flex;
		flex-direction: column;
	}

	.Main .itemCollection {
		display:flex;
		flex-direction: column;
		margin: 0 0 50px 0;
		width: 459px;
		/* height: 161px; */
		height: max-content;
		/* overflow: auto; */
	}

	.Main .itemCollection .collection-1 {
		display: flex;
		width: 459px;
		height: 161px;
		overflow: auto;
	}

	.Main .item {
		height: 125px;
		width: max-content;
		margin: 0;
		position: relative;
	}	

	.Main .item .imageWrapper {
		height: 125px;
		width: max-content;
	}	

	.Main .imageWrapper img {
		width: auto;
		height: 100%;
		object-fit: contain;
	}
	.Main .imageCollection img { margin: 0;}



	.Main .imageWrapper:hover a {
		backdrop-filter: blur(0);
		color: #282828;
		background-color: rgba(0, 0, 0, 0);
	}

	.Main .imageWrapper:hover p {
		opacity: 1;
	}

	.Main .item button {
		margin-top: 0px;
	}

	.Main .item button:hover {
		background-color: white;
		color: #282828;
	}
}


@media only screen and (max-width: 459px) {
	.Main {
		max-width: 100vw;
	}

	.Main .outerItemCollection {
		margin: 30px 0 200px 0;
	}

	.Main .imageCollection {
		margin-top: 32px;
		width: 100vw;
	}
	
	.Main .imageCollection div {
		width: 100vw;
		height: max-content;
		/* height: 200px; */
	}

	.Main .itemCollection .collection-1 {
		width: 100vw;
	}
	
	.Main .imageCollection img {
		width: 100vw;
		height: auto;
		object-fit: cover;
	}

	.Main .itemCollection {
		margin: 0px 0 0px 0;
		width: 100vw;
	}
}
