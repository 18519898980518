.SideMenu {
	width: 250px;
	min-height: 100vh;
	height: 100%;
	padding: 60px 0 36px 60px;
	box-sizing: border-box;
	order: 1;
	z-index: 100;
}

.SideMenu .fixed-wrapper {
	position: fixed;
}

.SideMenu h1 {
	margin: 0;
}

.SideMenu .header1 {
	margin: 0;
	font-size: 22px;
	color: #282828;
	font-weight: 800;
	cursor: pointer;
}

.SideMenu h2 {
	margin: 0; margin-top: 6px;
	font-size: 15px;
	letter-spacing: 2px;
	font-weight: 600;
	color: #282828;
}

.SideMenu p {
	font-size: 15px;
	font-weight: 600;
}

.SideMenu .filterSection {
	display: flex;
	margin-top: 66px;
}

.SideMenu .filterSection .iconWrapper {
	background-color: white;
	padding-left: 7px;
	padding-right: 13px;
	height: 18px;
	z-index: 11;
	display: flex;
	align-items: center;
}

.SideMenu .filterSection img {
	z-index: 11;
	transition: 0.4s;
	-webkit-transition: 0.4s;
	transform-origin: left left;
	-webkit-transform-origin: left left;
}

.SideMenu .genderContainer {
	display: flex;
	align-items: center;
	height: max-content;
	width: max-content;
}

.SideMenu .genderContainer p {
	top: 0;
	background-color: white;
}

.SideMenu .genderContainer .genderLink {
	top: 0; left: 0;
	background-color: white;
	padding: 0;
	font-size: 15px;
	font-weight: 600;
	font-family: 'Montserrat', sans-serif;
	transition: 0.5s;
	-webkit-transition-timing-function: cubic-bezier(0.52, 0.16, 0.24, 1);
	transition-timing-function: cubic-bezier(0.52, 0.16, 0.24, 1);
	text-decoration: none;

}





.SideMenu .seasonContainer {
	display: flex;
	align-items: center;
	height: max-content;
	width: max-content;
	/* margin-left: 18px; */
}


/* ****** FOOTER ****** */
.SideMenu footer {
	position: absolute;
	bottom: 60px;
}

.SideMenu .contact {
	font-size: 12px;
	letter-spacing: 2px;
	text-transform: uppercase;
	text-decoration: none;
	color: #282828;
}

.SideMenu .line {
	margin: 18px 0;
	width: 36px;
	height: 0.0625rem;
	background-color: #282828;
}

.SideMenu a {
	font-size: 8px;
	letter-spacing: 2px;
	line-height: 10px;
}

.SideMenu a span {
	font-size: 12px;
	letter-spacing: 0px;
	font-weight: 700;
}




/* phone */
@media only screen and (max-width: 1110px) {
	.SideMenu {
		align-self: center;
		width: 459px;	
		min-height: 0;
		height: 100%;
		padding: 40px 0 0 0px;
		box-sizing: border-box;
		order: 1;
	}

	.SideMenu .fixed-wrapper {
		position: static;
	}
}

/* phone */
@media only screen and (max-width: 459px) {
	.SideMenu {
		align-self: flex-start;
		width: 100vw;	
		min-height: 0;
		height: 100%;
		padding: 22px 0 0 32px;
		box-sizing: border-box;
		order: 1;
	}

	
	.SideMenu h2 {
		margin-top: 0;
		font-size: 9px;
		letter-spacing: 1.2px;
	}

	.SideMenu p {
		font-size: 14px;
	}
	
	.SideMenu .filterSection {
		margin-top: 24px;
	}
}