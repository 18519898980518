body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'Montserrat', sans-serif;
  color: #282828;
  padding: 0;
}

a {
  text-decoration: none;
  color: #282828;
}

a :active {
  color: #282828;
}


button {
  padding: 0;
	cursor: pointer;
	text-decoration: none;
	border: none;
	outline: none;
  -webkit-tap-highlight-color: transparent;
}

button :active {
  color: #282828;
}

p {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

@import url('https://fonts.googleapis.com/css?family=Montserrat:500,600,700,800&display=swap');
