
.SideCollection {
	position: absolute;

}


.SideWall {
	z-index: 100;
	position: fixed;
	width: 330px;
	top: 0;
	height: 100%;
	background-color: rgba(5, 5, 5, 1);
	transition: 0.35s ease-in-out;
	text-align: right;
	/* backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px); */
}

/* .Preview-sideMenu div { background-color: rgba(0,0,0,0.0);} */




 .SideWall button {
	margin: 25px 25px 0 0;
	padding: 10px;
	box-sizing: content-box;
	position: relative;
	width: 27px;
	height: 21px;
	background-color: rgba(0,0,0,0.0);
}

  .SideWall button div {
	background-color: white;
	display: block;
	position: absolute;
	height: 2.3px;
	width: 27px;
	/* -webkit-transition: .3s ease-in-out;
	-moz-transition: .3s ease-in-out;
	-o-transition: .3s ease-in-out;
	transition: .3s ease-in-out; */
}

 .topLayer {
	top: 4px;
	/* -webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg); */
}

 .bottomLayer {
	top: 14.7px;
	/* -webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg); */
}

 .topLayer-open {
	top: 9px;
	-webkit-transform: rotate(135deg);
	-moz-transform: rotate(135deg);
	-o-transform: rotate(135deg);
	transform: rotate(135deg);
}

 .bottomLayer-open {
	top: 9px;
	-webkit-transform: rotate(-135deg);
	-moz-transform: rotate(-135deg);
	-o-transform: rotate(-135deg);
	transform: rotate(-135deg);
}




/* Collection */
.SideCollection .collection {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 0 60px 0;
	height: calc(100vh - 41px);
	position: relative;
	overflow: auto;
}

.SideCollection .imageWrapper {
	width: max-content;
	height: max-content;
	margin-top: 40px;
	position: relative;
}

.SideCollection .imageWrapper a {
	left: 0; top: 0;
	width: 100%;
	height: calc(100% - 4px);
	position: absolute;
	transition: 0.21s ease-in;
	-webkit-transition: .21s ease-in;
	color: rgb(255, 255, 255, 0);
	display: flex;
	justify-content: center;
	align-items: center;
}

.SideCollection .imageWrapper img {
	width: 211px;
	height: auto;
	object-fit: contain;
}




