.Page404 {
	display: flex;
}

.Page404 .content-wrapper {
	position: absolute;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Page404 .content-wrapper p {
	font-size: 50px;
	font-weight: 700;
	letter-spacing: 10px;
	text-transform: uppercase;
}

.Page404 .content-wrapper p span {
	font-size: 18px;
	font-weight: 700;
	letter-spacing: 0px;
}